import React from "react"
import {  Link } from "gatsby";
import {StaticImage} from "gatsby-plugin-image"
import { Linkedin, Twitter } from "grommet-icons"

import { Box, Paragraph } from "grommet"

import theme from "../themes/po3Theme.json"

import styled from "styled-components"

const FooterLink = styled(Link)`
  color: ${theme.global.colors.text.dark};
  text-decoration: none;
  :hover {
    text-decoration: underline;
  }
`
const FooterA = styled.a`
  color: ${theme.global.colors.text.dark};
  text-decoration: none;
  :hover {
    text-decoration: underline;
  }
`

export default function  PoFooter() {

  return (
    <Box
      background={{
        dark: true,
        color: "background-back",
      }}
    >
      <Box
        width="xlarge"
        alignSelf="center"
        pad={{ vertical: "xlarge", horizontal: "medium" }}
      >
        <Box justify="between" gap="xlarge" direction="row-responsive">
          <Box basis="1/2" direction="row">
            <Box basis="1/2" gap="medium">
              <FooterLink to="/">Home</FooterLink>
              <FooterLink to="/services">Services</FooterLink>
              <FooterLink to="/manifesto">Manifesto</FooterLink>
              <FooterLink to="/about">About</FooterLink>
              <FooterLink to="/journal">Journal</FooterLink>
            </Box>
            <Box basis="1/2" gap="medium">
              <FooterLink to="/book">Book</FooterLink>
              <FooterLink to="/contact">Contact</FooterLink>
              <FooterLink to="/modern-slavery-statement">Modern Slavery Statement</FooterLink>
              <FooterLink to="/privacy">Privacy</FooterLink>
              <FooterLink to="/signup">Subscribe</FooterLink>
            </Box>
          </Box>

          <Box basis="1/2" direction="row">
            <Box basis="1/2" gap="medium">
              <Box align="start">
                <Box width="xsmall">
                  <StaticImage
                    padding={{ right: "medium" }}
                    alt="B Corportion pending logo"
                    src="../static/images/Certified_B_Corporation_PENDING_White-LG.png"
                  />
                </Box>
              </Box>
            </Box>
            <Box basis="1/2" gap="medium">
              <Box align="center" gap="medium" direction="row">
                <FooterA
                  rel="noreferrer"
                  target="_blank"
                  href="https://www.linkedin.com/company/po3"
                >
                  <Linkedin />
                </FooterA>
                <FooterA
                  rel="noreferrer"
                  href="https://twitter.com/po3_ventures"
                >
                  <Twitter />
                </FooterA>
              </Box>

              <Box>
                <Paragraph size="small" margin={{ bottom: "none" }}>
                  We're incorporated in England and Wales under{" "}
                  <FooterA
                    rel="noreferrer"
                    target="_blank"
                    href="https://find-and-update.company-information.service.gov.uk/company/12753406"
                  >
                    company number 12753406
                  </FooterA>
                  . Registered at 3rd Floor 86-90 Paul St, London UK. EC2A 4NE
                </Paragraph>
                <Paragraph size="small" margin={{ bottom: "none" }}>
                  © {new Date().getFullYear()} Po3. All rights reserved.
                </Paragraph>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
