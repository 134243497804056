import React from "react"
import { Link } from "gatsby"

import {
  Header,
  Button,
  Box,
  Image,
  Text,
  Layer,
  ResponsiveContext,
} from "grommet"

import { Menu as MenuIcon } from "grommet-icons"
import { Close } from "grommet-icons"

import logoUrl from "../static/images/po3.svg"
import whitelogoUrl from "../static/images/po3-white.svg"
import theme from "../themes/po3Theme.json"
import styled from "styled-components"

const ResponsiveMenu = styled(Box)`
  @media (max-width: 768px) {
    display: none;
  }
`

export default function Navigation({ grommetTheme }) {
  const [show, setShow] = React.useState("")

  const linkStyle = {
    textDecoration: "none",
    color: grommetTheme
      ? theme.global.colors.text.dark
      : theme.global.colors.text.light,
    paddingBottom: `10px`,
  }

  const active = {
    color: theme.global.colors.brand.light,
    borderBottom: `2px solid ${theme.global.colors.brand.light}`,
  }

  const mobileActive = {
    opacity: "0.5",
  }

  return (
    <div>
      <Header pad={{ vertical: "medium", horizontal: "small" }} height="xsmall">
        <Link name="Home link" to="/">
          <Box width="xxsmall" height="xxsmall">
            <Image
              fit="contain"
              alt="po3 logo"
              src={grommetTheme === "dark" ? whitelogoUrl : logoUrl}
            />
          </Box>
        </Link>

        <ResponsiveContext.Consumer>
          {size =>
            size === "small" ? (
              <Box>
                {show && (
                  <Layer
                    onEsc={() => setShow(false)}
                    onClickOutside={() => setShow(false)}
                  >
                    <Box
                      overflow="auto"
                      style={{
                        position: "fixed",
                        top: "0",
                        left: "0",
                        right: "0",
                        height: "100%",
                      }}
                      background="brand"
                      pad={{ vertical: "medium", horizontal: "medium" }}
                    >
                      <Header
                        pad={{ vertical: "medium", horizontal: "small" }}
                        height="xsmall"
                      >
                        <Link name="Home link" to="/">
                          <Box width="xxsmall" height="xxsmall">
                            <Image
                              fit="contain"
                              alt="po3 logo"
                              src={whitelogoUrl}
                            />
                          </Box>
                        </Link>
                        <Button
                          icon={<Close color="black" size="small" />}
                          label="Close"
                          size="small"
                          primary
                          color="white"
                          onClick={() => setShow(false)}
                        />
                      </Header>
                      <Box
                        justify="start"
                        margin={{ top: "xlarge", bottom: "xlarge" }}
                      >
                        <Link
                          style={{ textDecoration: "none" }}
                          to="/services"
                          partiallyActive={true}
                          activeStyle={mobileActive}
                        >
                          <Box
                            pad={{ vertical: "medium", horizontal: "large" }}
                          >
                            <Text
                              size="xxlarge"
                              alignSelf="end"
                              weight="500"
                              color="white"
                            >
                              services
                            </Text>
                          </Box>
                        </Link>
                        <Link
                          style={{ textDecoration: "none" }}
                          activeStyle={mobileActive}
                          to="/manifesto"
                        >
                          <Box
                            pad={{ vertical: "medium", horizontal: "large" }}
                          >
                            <Text
                              size="xxlarge"
                              alignSelf="end"
                              weight="500"
                              color="white"
                            >
                              manifesto
                            </Text>
                          </Box>
                        </Link>
                        <Link
                          activeStyle={mobileActive}
                          style={{ textDecoration: "none" }}
                          to="/about"
                        >
                          <Box
                            pad={{ vertical: "medium", horizontal: "large" }}
                          >
                            <Text
                              size="xxlarge"
                              alignSelf="end"
                              weight="500"
                              color="white"
                            >
                              about
                            </Text>
                          </Box>
                        </Link>
                        <Link
                          activeStyle={mobileActive}
                          style={{ textDecoration: "none" }}
                          to="/journal"
                        >
                          <Box
                            pad={{ vertical: "medium", horizontal: "large" }}
                          >
                            <Text
                              size="xxlarge"
                              alignSelf="end"
                              weight="500"
                              color="white"
                            >
                              journal
                            </Text>
                          </Box>
                        </Link>
                        <Link
                          activeStyle={mobileActive}
                          style={{ textDecoration: "none" }}
                          to="/contact"
                        >
                          <Box
                            pad={{ vertical: "medium", horizontal: "large" }}
                          >
                            <Text
                              size="xxlarge"
                              alignSelf="end"
                              weight="500"
                              color="white"
                            >
                              contact
                            </Text>
                          </Box>
                        </Link>
                      </Box>
                    </Box>
                  </Layer>
                )}
                <Button
                  icon={
                    <MenuIcon
                      color={grommetTheme === "dark" ? "white" : "black"}
                      size="small"
                    />
                  }
                  size="small"
                  color={grommetTheme === "dark" ? "white" : "brand"}
                  label="Menu"
                  onClick={() => setShow(true)}
                />
              </Box>
            ) : (
              <ResponsiveMenu justify="end" direction="row" gap="medium">
                <Link
                  activeStyle={active}
                  partiallyActive={true}
                  style={linkStyle}
                  to="/services"
                >
                  <Text size="large">services</Text>
                </Link>
                <Link activeStyle={active} style={linkStyle} to="/manifesto">
                  <Text size="large">manifesto</Text>
                </Link>
                <Link activeStyle={active} style={linkStyle} to="/about">
                  <Text size="large">about</Text>
                </Link>
                <Link activeStyle={active} style={linkStyle} to="/journal">
                  <Text size="large">journal</Text>
                </Link>
                <Link activeStyle={active} style={linkStyle} to="/contact">
                  <Text size="large">contact</Text>
                </Link>
              </ResponsiveMenu>
            )
          }
        </ResponsiveContext.Consumer>
      </Header>
    </div>
  )
}
