import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import PropTypes from "prop-types"
import { Box, Grommet } from "grommet"
import "fontsource-dm-sans/400.css"
import "fontsource-dm-sans/500.css"
import { Helmet } from "react-helmet"

import po3theme from "../themes/po3Theme.json"
import Header from "./header"
import Footer from "./footer"
import "../static/assets/styles.css"

const Layout = ({ ...children }) => {
  const logo = useStaticQuery(
    graphql`
    query GetLogo {
      file(relativePath: {eq: "favicon.png"}) {
        publicURL
      }
    }
    `
  )
  return (
    <Grommet themeMode={children.themeMode} theme={po3theme}>
      <Helmet>
        <script type="application/ld+json">
          {`
        {
          "@context": "https://schema.org",
          "@type": "Organization",
          "url": "https://www.po3.ventures",
          "name": "Po3",
          "logo": "https://www.po3.ventures${logo.file.publicURL}"
        }
      `}
        </script>
      </Helmet>
      <Box>
        <Box
          width="xlarge"
          alignSelf="center"
          pad={{ vertical: "medium", horizontal: "medium" }}
        >
          <Header grommetTheme={children.themeMode} />
        </Box>
        <Box as="main">{children.children}</Box>
        <Footer />
      </Box>
    </Grommet>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
